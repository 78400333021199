import * as React from 'react';
import Layout from '../../../components/Layout';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Container, Link, Typography, Stack, Modal } from '@mui/material';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

type ProductProps = {
  data: GatsbyTypes.ProductQuery;
  params: { strapiId: string };
}

export default function Product({ data }: ProductProps): JSX.Element {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      console.log("greetings");
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setModalOpen(open);
    };

  const p = data.allStrapiProducts.nodes[0];
  const image = (forModal: boolean) => (
    <span
      onClick={forModal ? null : toggleModalOpen(true)}
      onKeyDown={forModal ? null : toggleModalOpen(true)}
    >
      <GatsbyImage
        image={p.image.localFile.childImageSharp.gatsbyImageData}
        alt=""
        loading="eager"
        objectFit="contain"
        css={forModal ? { maxWidth: '95vw', maxHeight: '95vh', backgroundColor: 'white', width: 'auto' } : {
          cursor: 'pointer',
          maxHeight: '27rem',
          height: '100%',
          marginBottom: '1rem',
        }}
      />
    </span>
  );

  return (
    <Layout title={`${p.name} | Oclee & Son`}>
      <Stack minHeight="100vh">
        <Header page="catalogue" />
        <Box flex={1}>
          <Container>
            <Box mb={2}>
              <Link href={`/catalogue/category/${p.category.id}`}>
                &lt;&nbsp;&nbsp;Back to {p.category.name}
              </Link>
            </Box>
            <Box>
              <Box>
                <Typography variant="h1" fontSize="2rem" gutterBottom>
                  { p.name }
                  <br />
                  { p.price > 0 ? `£${p.price.toFixed(2)}` : "Price on Application" }
                </Typography>
                <Box mb="1rem">
                  <ul css={{listStyle: 'inside'}}>
                    {
                      [
                        p.model ? <li>Model: {p.model}</li> : undefined,
                        p.manufacturer?.name ? <li>Manufactured by: {p.manufacturer.name}</li> : undefined,
                      ]
                    }
                  </ul>
                  <Box mb="1rem" dangerouslySetInnerHTML={{__html: p.description}} />
                </Box>
              </Box>
              <Box>
                {image(false)}
                <Modal
                  open={modalOpen}
                  onClose={toggleModalOpen(false)}
                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                  disablePortal
                >
                  <Box>
                    {image(true)}
                  </Box>
                </Modal>
              </Box>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}

export const query = graphql`
query Product($strapiId: Int!) {
  allStrapiProducts(
    filter: {
      strapiId: {eq: $strapiId},
      sort_order: {gte: 0}
    }
  ) {
    nodes {
      name
      price
      model
      manufacturer {
				name
      }
      description
      category {
				id
        name
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
            )
          }
        }
      }
    }
  }
}
`;
